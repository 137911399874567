import Cookies from 'js-cookie'

document.addEventListener('DOMContentLoaded', userLocaleHandling )

function userLocaleHandling() {
  let domain = document.head.querySelector('meta[name=host]').content;
  let alternateLocale = document.head.querySelector('meta[name=alternate-locale]').content;
  let userLocale = Cookies.get("locale", { domain: domain });

  if (userLocale) {
    let alternatePageUrl = document.head.querySelector(`link[hreflang=${userLocale}-UA`).href;

    if (userLocale == alternateLocale) { window.location = alternatePageUrl };
  } else {
    return;
  }
}
